/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Navigation } from "../components/Navigation";
import image1 from "../img/freestocks-nss2eRzQwgw-unsplash.jpg";
import image2 from "../img/hal-gatewood-iPl3q-gEGzY-unsplash.jpg";
import image3 from "../img/us.jpg";
import { Footer } from "../components/Footer";
import image11 from "../img/new/hepaklin3.jpg";
import image15 from "../img/new/man1.jpg";
import image16 from "../img/new/girl1.jpg";

export const About = () => {
  return (
    <div>
      <Navigation />

      {/* <!-- services --> */}
      <div className="services">
        <div className="container">
          <p className="w3ls_para">Kachelan</p>
          <h3 className="agileits_head">ABOUT US</h3>
          <div className="w3l_services_grids">
            <div>
              <ul className="nav nav-tabs">
                <li className="active">
                  <a data-toggle="tab" href="#home">
                    VISION STATEMENT
                  </a>
                </li>

                <li>
                  <a data-toggle="tab" href="#menu2">
                    OUR MISSION
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#menu3">
                    OUR OBJECTIVE
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div id="home" className=" tab-pane show fade active">
                  <div className="row">
                    <div className="col-md-7 w3layouts_vertical_tab">
                      <h4>
                        To be the hallmark in the delivery of innovative quality
                        pharmaceutical products and solutions that enhances life
                        and healthy living.
                      </h4>
                    </div>
                    <div className="col-md-5 w3layouts_vertical_tab_right">
                      {" "}
                      <img src={image1} alt="about " className="img-fluid" />
                    </div>
                    <div className="clearfix"> </div>
                  </div>
                </div>
                <div id="menu2" className=" tab-pane fade">
                  <div className="row">
                    <div className="col-md-7 w3layouts_vertical_tab">
                      <h4>
                        To provide world class products through the deployment
                        of research and development in alignment with global
                        best practices and the use of highly skilled human
                        resources towards the delivery of proven pharmaceutical
                        products
                      </h4>
                    </div>
                    <div className="col-md-5 w3layouts_vertical_tab_right">
                      {" "}
                      <img src={image2} alt="about " className="img-fluid" />
                    </div>
                    <div className="clearfix"> </div>
                  </div>
                </div>
                <div id="menu3" className=" tab-pane fade">
                  <div className="row mx-auto align-items-center">
                    <div className="col-md-7">
                      <h4>
                        Our values reflect who we are and what we stand for as a
                        company:
                      </h4>
                      <li>
                        Constant improvement of its pharmaceutical products’
                        technical performance
                      </li>
                      <li>
                        Constant research and development of drugs that are
                        affordable, available and ethical.
                      </li>
                      <li>
                        Alongside its core activities, development of new
                        technologies and/or products to support sustainable
                        health care and public health safety.
                      </li>
                      <li>
                        Increase sales to an annual value of 125 million dollars
                        in the next 10 years through development of products &
                        services and channels across Africa, Europe and Asia.
                      </li>
                    </div>
                    <div className="col-md-5">
                      {" "}
                      <img
                        src={image3}
                        alt=" about"
                        className="img-fluid w-75"
                      />{" "}
                    </div>
                    <div className="clearfix"> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- //services -->
    <!-- services-bottom --> */}
      <div className="services-bottom">
        <div className="container">
          <p className="w3ls_para">Kachelan</p>
          <h3 className="agileits_head agileits_head_w3">OUR VALUES</h3>
          <div className="w3l_services_bottom_grids row">
            <div className="col-md-3 w3l_services_bottom_grid">
              <div className="w3ls_services_bottom_grid hvr-rectangle-out">
                {" "}
                <i className="fa fa-users" aria-hidden="true"></i>{" "}
              </div>
              <h4>Customer Commitment</h4>
              <p>
                We develop products and services that meet the customers’
                expectation .
              </p>
            </div>
            <div className="col-md-3 w3l_services_bottom_grid">
              <div className="w3ls_services_bottom_grid hvr-rectangle-out">
                {" "}
                <i className="fa fa-certificate" aria-hidden="true"></i>{" "}
              </div>
              <h4>Respect for Others</h4>
              <p>
                We value our people, encourage their development and reward
                outstanding performance.
              </p>
            </div>
            <div className="col-md-3 w3l_services_bottom_grid">
              <div className="w3ls_services_bottom_grid hvr-rectangle-out">
                {" "}
                <i className="fa fa-pagelines" aria-hidden="true"></i>{" "}
              </div>
              <h4>Quality</h4>
              <p>
                {" "}
                We provide outstanding products and unsurpassed services that
                together deliver premium value to our customers
              </p>
            </div>
            <div className="col-md-3 w3l_services_bottom_grid">
              <div className="w3ls_services_bottom_grid hvr-rectangle-out">
                {" "}
                <i className="fa fa-calculator" aria-hidden="true"></i>{" "}
              </div>
              <h4>Honesty and Accountability</h4>
              <p>
                We hold ourselves, individually and collectively, accountable to
                all our partners on our promises.
              </p>
            </div>
            <div className="clearfix"> </div>
          </div>

          <div className="w3l_services_bottom_grids row">
            <div className="col-md-3 w3l_services_bottom_grid">
              <div className="w3ls_services_bottom_grid hvr-rectangle-out">
                {" "}
                <i className="fa fa-users" aria-hidden="true"></i>{" "}
              </div>
              <h4>Integrity</h4>
              <p>
                The uphold the highest standard of integrity in our dealings.
              </p>
            </div>
            <div className="col-md-3 w3l_services_bottom_grid">
              <div className="w3ls_services_bottom_grid hvr-rectangle-out">
                {" "}
                <i className="fa fa-certificate" aria-hidden="true"></i>{" "}
              </div>
              <h4>Courage</h4>
              <p>
                We always play to win by exhibiting strong characters in the
                market place and all our business endeavours.
              </p>
            </div>
            <div className="col-md-3 w3l_services_bottom_grid">
              <div className="w3ls_services_bottom_grid hvr-rectangle-out">
                {" "}
                <i className="fa fa-pagelines" aria-hidden="true"></i>{" "}
              </div>
              <h4>Team work</h4>
              <p>
                {" "}
                Winning together requires we work together across professions,
                tribes, race and colours to ensure we attain the best possible
                service and product experience.
              </p>
            </div>
            <div className="col-md-3 w3l_services_bottom_grid">
              <div className="w3ls_services_bottom_grid hvr-rectangle-out">
                {" "}
                <i className="fa fa-calculator" aria-hidden="true"></i>{" "}
              </div>
              <h4>Good citizenship</h4>
              <p>
                We are committed to good relationship with all our host
                communities.
              </p>
            </div>
            <div className="clearfix"> </div>
          </div>
        </div>
      </div>
      {/* <!-- //services-bottom -->
    <!-- featured-services --> */}
      <div className="featured-services">
        <div className="container">
          <p className="w3ls_para">KACHELAN</p>
          <h3 className="agileits_head">Latest Products</h3>
          <div className=" row">
            <div className="col-md-4 ">
              <img src={image11} alt="product " className="img-fluid" />
            </div>
            <div className="col-md-4">
              <img src={image15} alt="product " className="img-fluid h-100" />
            </div>
            <div className="col-md-4">
              <img src={image16} alt="product " className="img-fluid" />
            </div>

            <div className="clearfix"> </div>
          </div>
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              {" "}
              <a href="/product">
                {" "}
                <button className="pro">SEE ALL PRODUCTS</button>
              </a>{" "}
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
      </div>

      <br />
      <Footer />
    </div>
  );
};
