import counterUp from "counterup2";

export const counts = () => {
  const el = document.querySelector(".counter");
  const el2 = document.querySelector(".counter2");
  const el3 = document.querySelector(".counter3");
  const el4 = document.querySelector(".counter4");

  // Start counting, typically you need to call this when the
  // element becomes visible, or whenever you like.
  counterUp(el, {
    duration: 3000,
    delay: 10,
  });
  counterUp(el2, {
    duration: 3000,
    delay: 10,
  });
  counterUp(el3, {
    duration: 3000,
    delay: 10,
  });

  counterUp(el4, {
    duration: 3000,
    delay: 10,
  });
};
