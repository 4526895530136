import React from "react";
import { Footer } from "../components/Footer";
import { Navigation } from "../components/Navigation";

export const Career = () => {
  return (
    <div>
      <Navigation />

      <div class="mail">
        <div class="container">
          <p class="w3ls_para">Kachelan</p>
          <h3 class="agileits_head">Join Our Team</h3>
          <h5
            class="text-center"
            style={{
              alignItems: "center",
              fontSize: "18px",
              marginTop: "10px",
              lineHeight: "1.5",
            }}
          >
            At Kachelan, you get to work and interact with a team of highly
            skilled and dedicated professionals, just like you. <br />
            Think you are the right fit for us?, fill the form below and we will
            be in touch with you if you meet our requirements.
          </h5>
          <div class="w3_agile_mail_grids">
            <form
              action="/application"
              method="POST"
              enctype="multipart/form-data"
            >
              <div class="col-md-6 w3_agile_mail_grid">
                {" "}
                <span class="input input--ichiro">
                  {" "}
                  <input
                    class="input__field input__field--ichiro"
                    type="text"
                    id="input-25"
                    placeholder=" "
                    required=""
                    name="full"
                  />{" "}
                  <label
                    class="input__label input__label--ichiro"
                    for="input-25"
                  >
                    {" "}
                    <span class="input__label-content input__label-content--ichiro">
                      Full Name
                    </span>{" "}
                  </label>{" "}
                </span>{" "}
                <span class="input input--ichiro">
                  {" "}
                  <input
                    class="input__field input__field--ichiro"
                    type="email"
                    id="input-26"
                    placeholder=" "
                    required=""
                    name="email"
                  />{" "}
                  <label
                    class="input__label input__label--ichiro"
                    for="input-26"
                  >
                    {" "}
                    <span class="input__label-content input__label-content--ichiro">
                      Email
                    </span>{" "}
                  </label>
                </span>{" "}
                <span class="input input--ichiro">
                  {" "}
                  <input
                    class="input__field input__field--ichiro"
                    type="file"
                    id="input-27"
                    placeholder=" "
                    required=""
                  style={{cursor:'pointer'}}
                    name="cv"
                  />{" "}
                  <label
                    class="input__label input__label--ichiro"
                    for="input-27"
                  >
                    {" "}
                    <span class="input__label-content input__label-content--ichiro">
                      Upload your CV
                    </span>
                  </label>{" "}
                </span>{" "}
              </div>
              <div class="col-md-6 w3_agile_mail_grid">
                {" "}
                <textarea
                  name="Message"
                  placeholder="Your cover letter"
                  required=""
                ></textarea>{" "}
              </div>{" "}
              <input
                type="submit"
                value="Submit"
                class="got"
                //onclick="swal('Great!','Application Submitted', 'success')"
              />
              {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/sweetalert/2.1.2/sweetalert.min.js"></script> */}
              <div class="clearfix"> </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
