import image1 from "./img/new/kachprovyte1.jpg";
import image2 from "./img/new/kachprovyte2.jpg";
import image3 from "./img/new/kachflox1.jpg";
import image4 from "./img/new/kachflox2.jpg";
import image41 from "./img/new/kachflox3.jpg";
import image5 from "./img/new/kachyvte1.jpg";
import image6 from "./img/new/kachyvte1.jpg";
import image7 from "./img/new/kachvomec1.jpg";
import image8 from "./img/new/kachvomec2.jpg";
import image9 from "./img/new/hepaklin1.jpg";
import image10 from "./img/new/hepaklin2.jpg";

import image12 from "./img/new/bioshield1.jpg";
import image13 from "./img/new/bioshield2.jpg";

import image15 from "./img/new/kachcal1.jpg";
import image16 from "./img/new/kachcal2.jpg";

import image17 from "./img/new/kachment1.jpg";
import image18 from "./img/new/kachment2.jpg";

import image19 from "./img/new/kachidox1.jpg";
import image20 from "./img/new/kachidox2.jpg";
import image21 from "./img/new/kachidox3.jpg";

export const products = [
  {
    id: "1",
    name: "Kachprovyte",
    composition:
      "Contains 17 Amino Acids, 6 Vitamins and several Electrolytes necessary for optimal growth in broilers, pullets and other young animals.",
    images: [image1, image2],
    size: "500ML & 1L PET bottles",
    desc: "Ideal for improved egg size and stress management.",
  },

  {
    id: "2",
    name: "Kachflox 20",
    composition: "Each ml contains 200mg of Ciprofloxacin Hydrochloride.",
    images: [image3, image4, image41],
    size: "100ML & 1L PET bottles",
    desc: " Formulated for the treatment of various bacterial infections of poultry, rabbit, fish, ruminant etc.",
  },

  {
    id: "3",
    name: "Kachvyte",
    composition:
      "A blend of Multivitamins specially formulated for optimal physiological and metabolic performance in poultry (Layers), Swine and other livestock. ",
    dosage: "1ml to every 6 litres of drinking water",
    images: [image5, image6],
    size: "500ML & 1L PET bottles",
    desc: "Ideal for stress management.",
  },

  {
    id: "4",
    name: "Kachvomec",
    composition: "1% Ivermectin Oral Solution",
    images: [image7, image8],
    size: "100ML, 1L PET bottles",
    desc: "Specially designed as a safe broad spectrum parasiticide for the prevention and control of external prasites (Mites, Lice etc) and internal parasites (Trichostrongylus, Ascaris, Stronglylus etc) of poultry, ruminants, pigs, wild animals and fishes.",
  },

  {
    id: "5",
    name: "Hepaklin (Liver Tonic)",
    composition:
      "Hepaklin contains Silymarin, a potent hepatoprotective agent derived from milk of thistle.",

    images: [image9, image10],
    size: "500ML, 1L PET bottles",
    desc: "For the treatment and management of Liver disorders associated with Fatty Liver Disease, Mycotoxicosis (eg Aflatoxicosis, Ochratoxicosis etc), Liver degeneration, Stress management, debeaking, cannibalism etc",
  },

  {
    id: "6",
    name: "Bioshield Oral",
    composition:
      "A blend of Organic Acids carefully formulated as an alternative to antibiotic use as growth promotes in food animals.",

    images: [image12, image13],
    size: "500ML & 1L PET bottles",
    desc: "* Safe alternative for the prevention and control of bacteria (eg Salmonella spp, Klebsiella spp, E. Coli etc) and fungi. *Promotes Gut Health by encouraging growth of beneficial gut organisms and inhibiting harmful gut organism. * Promotes Growth by improving FCR.* Inhibit slime buildup in water line",
  },

  {
    id: "7",
    name: "Kachcal D+ (Calcium Suspension)",
    composition:
      "  Calcium Carbonate, Sodium Diphosphate, Magnesium Sulphate, Arshine, Lysine, Vit B12, Vit A, Vit D, Vit E, Dextrose, Pantothenate",
    images: [image15, image16],
    size: "500ML & 1L PET bottles",
    desc: "Recommended for poultry and Ruminant for the prevention and management of Skeletal related disorders, poor egg production, poor egg shell quality, milk fever, and anemia.",
  },

  {
    id: "8",
    name: "Kachment-B",
    composition:
      "Contains Essential oils, Menthol, Benzoic Acid and Bromhexine HCl",

    images: [image17, image18],
    size: "100ML, 500ML & 1L PET bottles",
    desc: "Recommended for the  Prevention and Management of Respiratory problems of all class of poultry such as CRD. Ideal for management of stress associated with vaccination and reducing vaccine related adverse reaction in ND Lasota and IBV.    ",
  },

  {
    id: "8",
    name: "Kachdicox 2.5%",
    composition:
      "Contains 2.5% Diclazuril HCl and fortified with Vit K and Folic Acid",

    images: [image21, image20, image19 ],
    size: "100ML & 1L PET bottles",
    desc: "Kachdicox 2.5%* is recommended for the prevention and treatment of coccidiosis caused by Eimera spp, Isospora spp, and Toxoplasma spp in Poultry, Rabbit, Pigs and Ruminants. ",
    special:
      "Vit K composition facilitates blood clot and prevents excessive blood loss from injuries associated with coccidiosis. Folic Acid component promotes healing of damaged intestinal wall.",
  },
];
