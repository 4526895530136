import React from "react";
import { blogData } from "../blogData";
import { Footer } from "../components/Footer";
import { Navigation } from "../components/Navigation";

export const Blog = () => {
  return (
    <div>
      <Navigation />

      <div className="container">
        <p className="w3ls_para" style={{ marginTop: "30px" }}>
          KACHELAN
        </p>
        <h3 className="agileits_head">LATEST ARTICLES</h3>
        <div className="row" style={{ marginTop: "30px" }}>
            <div className="col-lg-1"></div>
            {blogData.map((item, i) => {
              return (
                <div key={i} className="col-lg-12" >
                  <h1 style={{ marginBottom: "20px" }}>{item.title}</h1>
                  <img src={item.image} alt="blog" className="img-fluid" />
                  <p
                    style={{
                      marginBottom: "20px",
                      marginTop: "20px",
                      fontSize: "17px",
                    }}
                  >
                    {item.row1}
                  </p>
                  <p style={{ marginBottom: "20px", fontSize: "17px" }}>
                    {item.row2}
                  </p>
                  <p
                    style={{
                      marginBottom: "150px",
                      marginTop: "20px",
                      fontSize: "17px",
                    }}
                  >
                    {item.row3}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
};
