import React, {useState, useEffect} from "react";
import boom from "../../img/boom.png";

export const Navigation = () => {
  const [navbar, setNavbar] = useState(false);
  const [show, setShow] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 280) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  return (
    <div class="banner1">
      <div className="">
          <nav
            className={
              navbar
                ? "navbar navbar-expand-md fixed-top active-navb"
                : "navbar navbar-expand-md fixed-top no-navb "
            }
          >
            <a className="navbar-brand ml-5" href="/">
              <img src={boom} alt="" width="70" />
            </a>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon" onClick={()=>setShow(true)}>
                {" "}
                <i class="fa fa-bars" aria-hidden="true"></i>{" "}
              </span>
            </button>

            <div
              className="collapse navbar-collapse d-none d-lg-block"
              id="collapsibleNavbar"
            >
              <ul className="nav navbar-nav link-effect-5 " id="link-effect-5">
                <li className="active">
                  <a href="/" className="navb-text" data-hover="Home">
                    Home
                  </a>
                </li>
                <li className="active">
                  <a href="/about" className="navb-text" data-hover="About">
                    About
                  </a>
                </li>
                <li className="active">
                  <a
                    href="/product"
                    className="navb-text"
                    data-hover="Products"
                  >
                    Products
                  </a>
                </li>
                <li className="active">
                  <a href="/blog" className="navb-text" data-hover="Blog">
                    Blog
                  </a>
                </li>
                <li className="active">
                  <a href="/newsletter" className="navb-text" data-hover="Blog">
                    Newsletter
                  </a>
                </li>
                <li className="active">
                  <a href="/career" className="navb-text" data-hover="Careers">
                    Careers
                  </a>
                </li>
                <li className="active">
                  <a
                    href="/contact"
                    className="navb-text"
                    data-hover="Contact Us"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>

              <div className="w3_social_icons ml-5">
                <ul className="w3layouts_social">
                  <li className="mx-3">
                    <a
                      href="https://www.facebook.com/779634665731490?referrer=whatsapp"
                      className="w3l_facebook"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li className="mx-3">
                    <a href="#" className="w3l_dribble">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li className="mx-3">
                    <a href="#" className="w3l_instagram">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          {show && (
            <div id="mySidenav" className="sidenav">
              <span className="closebtn cursor mt-5 pt-2 " onClick={() => setShow(false)}>
                &times;
              </span>
              <ul className="mt-5 " id="link-effect-5">
                <li className="active">
                  <a href="/" className="navb-text" data-hover="Home">
                    Home
                  </a>
                </li>
                <li className="active">
                  <a href="/about" className="navb-text" data-hover="About">
                    About
                  </a>
                </li>
                <li className="active">
                  <a
                    href="/product"
                    className="navb-text"
                    data-hover="Products"
                  >
                    Products
                  </a>
                </li>
                <li className="active">
                  <a href="/blog" className="navb-text" data-hover="Blog">
                    Blog
                  </a>
                </li>
                <li className="active">
                  <a href="/newsletter" className="navb-text" data-hover="Blog">
                    Newsletter
                  </a>
                </li>
                <li className="active">
                  <a href="/career" className="navb-text" data-hover="Careers">
                    Careers
                  </a>
                </li>
                <li className="active">
                  <a
                    href="/contact"
                    className="navb-text"
                    data-hover="Contact Us"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
    </div>
  );
};
