import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import './App.css';
import { About } from "./pages/About";
import { Blog } from "./pages/Blog";
import { Newsletter } from "./pages/NewsLetter";
import { Career } from "./pages/Career";
import { ContactUs } from "./pages/ContactUs";
import { Products } from "./pages/Products";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/newsletter" element={<Newsletter />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/product" element={<Products />} />
      </Routes>
    </Router>
  );
}
