import React from "react";
import { Footer } from "../components/Footer";
import { Navigation } from "../components/Navigation";

export const ContactUs = () => {
  return (
    <div>
      <Navigation />
      <div className="mail">
        <div className="container">
          <div className="agileits_w3layouts_mail">
            <div className="col-md-4 agileits_w3layouts_mail_left">
              <div className="w3_agileits_mail_left">
                <h4>
                  <i className="fa fa-home" aria-hidden="true"></i>Address
                </h4>
                <p className="wthree_address">
                  4, Gbadebo Osoba Street, Monijesu Bus Stop, Adigbe Road,
                  Abeokuta,Ogun State, Nigeria.
                </p>
              </div>
            </div>
            <div className="col-md-4 agileits_w3layouts_mail_left">
              <div className="w3_agileits_mail_left">
                <h4>
                  <i className="fa fa-envelope" aria-hidden="true"></i>Email
                </h4>
                <div className="w3_agileits_mail_left1" style={{padding:"28px"}}>
                  {" "}
                  <a href="mailto:help@kachelan.com">help@kachelan.com</a>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-4 agileits_w3layouts_mail_left">
              <div className="w3_agileits_mail_left">
                <h4>
                  <i className="fa fa-phone" aria-hidden="true"></i>Call Us
                </h4>
                <div className="w3_agileits_mail_left1">
                  <p className="agileinfo_para">(+234) 90 524 683 11</p>
                  <p className="agileinfo_para">(+234) 81 320 411 72</p>
                </div>
              </div>
            </div>
            <div className="clearfix"> </div>
          </div>
          <p className="w3ls_para">Kachelan</p>
          <h3 className="agileits_head">Leave Us A Message</h3>
          <div className="w3_agile_mail_grids">
            <form action="/con" method="POST">
              <div className="col-md-6 w3_agile_mail_grid">
                {" "}
                <span className="input input--ichiro">
                  {" "}
                  <input
                    className="input__field input__field--ichiro"
                    type="text"
                    id="input-25"
                    placeholder=" "
                    required=""
                    name="cname"
                  />{" "}
                  <label
                    className="input__label input__label--ichiro"
                    for="input-25"
                  >
                    {" "}
                    <span className="input__label-content input__label-content--ichiro">
                      Your Name
                    </span>{" "}
                  </label>{" "}
                </span>{" "}
                <span className="input input--ichiro">
                  {" "}
                  <input
                    className="input__field input__field--ichiro"
                    type="email"
                    id="input-26"
                    placeholder=" "
                    required=""
                    name="cemail"
                  />{" "}
                  <label
                    className="input__label input__label--ichiro"
                    for="input-26"
                  >
                    {" "}
                    <span className="input__label-content input__label-content--ichiro">
                      Your Email
                    </span>{" "}
                  </label>{" "}
                </span>{" "}
                <span className="input input--ichiro">
                  {" "}
                  <input
                    className="input__field input__field--ichiro"
                    type="text"
                    id="input-27"
                    placeholder=" "
                    required=""
                    name="cphone"
                  />{" "}
                  <label
                    className="input__label input__label--ichiro"
                    for="input-27"
                  >
                    {" "}
                    <span className="input__label-content input__label-content--ichiro">
                      Your Phone Number
                    </span>{" "}
                  </label>{" "}
                </span>{" "}
              </div>
              <div className="col-md-6 w3_agile_mail_grid">
                {" "}
                <textarea
                  name="cMessage"
                  placeholder="Your message here..."
                  required=""
                ></textarea>{" "}
              </div>{" "}
              <input type="submit" value="Submit" className="got" />
              <div className="clearfix"> </div>
            </form>
          </div>
        </div>
      </div>
      <div className="agile_map">
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
            title="map"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=4%2C%20Gbadebo%20Osoba%20Street%2C%20Monijesu%20Bus%20Stop%2C%20Adigbe%20Road%2C%20Abeokuta%2COgun%20State&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
