/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Footer } from "../components/Footer";
import { Navigation } from "../components/Navigation";
import { products } from "../productsData";
import classname from "classname";

export const Products = () => {
  return (
    <div>
      <Navigation />
      <div className="mt-5 pt-5 product">
        <p className="w3ls_para">KACHELAN</p>
        <h3 className="agileits_head">Our Products</h3>

        <div className="container-fluid px-lg-5">
          <div className="row mt-5 justify-content-between px-sm-0 px-lg-5">
            {products.map((item, i) => (
              <div className="col-lg-4 mb-5" key={i}>
                <div className=" pro-divs pb-3">
                  <div
                    id={`custCarousel${i}`}
                    className="carousel slide custCarousel"
                    data-ride="carousel"
                    align="center"
                  >
                    <div className="carousel-inner">
                      {item?.images?.map((res, i) => (
                        <div
                          className={classname(
                            "carousel-item",
                            i === 0 ? "active" : ""
                          )}
                        >
                          <img key={i} src={res} alt="product" />
                        </div>
                      ))}
                    </div>
                    <a
                      className="carousel-control-prev"
                      href= {`#custCarousel${i}`}
                      data-slide="prev"
                    >
                      {" "}
                      <span className="carousel-control-prev-icon"></span>{" "}
                    </a>{" "}
                    <a
                      className="carousel-control-next"
                      href={`#custCarousel${i}`}
                      data-slide="next"
                    >
                      {" "}
                      <span className="carousel-control-next-icon"></span>{" "}
                    </a>
                    <h5 className=" mt-3">{item?.name}</h5> <br />
                    <ol className="carousel-indicators list-inline">
                      {item?.images.map((res, k) => (
                        <li
                          className={classname(
                            "list-inline-item",
                            k === 0 ? "active" : ""
                          )}
                          key={k}
                        >
                          <a
                            id={`carousel-selector-${k}`}
                            data-slide-to={k}
                            data-target={`#custCarousel${i}`}
                          >
                            <img
                              src={res}
                              className="img-fluid"
                              alt="product"
                            />
                          </a>
                        </li>
                      ))}
                    </ol>
                  </div>
                  <div className="text-center pt-3">
                    <a
                      type="button"
                      className="bn w-50 mx-auto mt-4"
                      data-toggle="modal"
                      data-target={`#id${i}`}
                    >
                      View details
                    </a>
                  </div>
                </div>

                <div className="modal fade" id={`id${i}`}>
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-title">{item?.name}</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                        >
                          &times;
                        </button>
                      </div>

                      <div className="modal-body">
                        <h5 className="font-weight-bolder mt-4">Composition</h5>
                        <p className="p-0 m-0">{item?.composition}</p>

                        <h5 className="font-weight-bolder mt-4">Description</h5>
                        <p className="p-0 m-0">{item?.desc}</p>

                        <h5 className="font-weight-bolder mt-4">Size</h5>
                        <p className="p-0 m-0">Available in {item?.size}</p>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
