/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import boom from "../img/boom.png";
import develop from "../img/logo3.png";
import blog1 from "../img/pou.jpg";
import { Footer } from "../components/Footer";
import { counts } from "../counter";
import YouTube from "react-youtube";
import image2 from "../img/new/man1.jpg";
import image3 from "../img/new/girl2.jpg";

export const Home = () => {
  useEffect(() => {
    counts();
  }, []);

  const onReady = (event) => {
    event.target.pauseVideo();
  };

  const [navbar, setNavbar] = useState(false);
  const [show, setShow] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 500) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  return (
    <div>
      <div className="banner">
        <div className="">
          <nav
            className={
              navbar
                ? "navbar navbar-expand-md fixed-top active-navb"
                : "navbar navbar-expand-md fixed-top no-navb "
            }
          >
            <a className="navbar-brand ml-5" href="/">
              <img src={boom} alt="" width="70" />
            </a>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon" onClick={()=>setShow(true)}>
                {" "}
                <i class="fa fa-bars" aria-hidden="true"></i>{" "}
              </span>
            </button>

            <div
              className="collapse navbar-collapse d-none d-lg-block"
              id="collapsibleNavbar"
            >
              <ul className="nav navbar-nav link-effect-5 " id="link-effect-5">
                <li className="active">
                  <a href="/" className="navb-text" data-hover="Home">
                    Home
                  </a>
                </li>
                <li className="active">
                  <a href="/about" className="navb-text" data-hover="About">
                    About
                  </a>
                </li>
                <li className="active">
                  <a
                    href="/product"
                    className="navb-text"
                    data-hover="Products"
                  >
                    Products
                  </a>
                </li>
                <li className="active">
                  <a href="/blog" className="navb-text" data-hover="Blog">
                    Blog
                  </a>
                </li>
                <li className="active">
                  <a href="/newsletter" className="navb-text" data-hover="Blog">
                    Newsletter
                  </a>
                </li>
                <li className="active">
                  <a href="/career" className="navb-text" data-hover="Careers">
                    Careers
                  </a>
                </li>
                <li className="active">
                  <a
                    href="/contact"
                    className="navb-text"
                    data-hover="Contact Us"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>

              <div className="w3_social_icons ml-5">
                <ul className="w3layouts_social">
                  <li className="mx-3">
                    <a
                      href="https://www.facebook.com/779634665731490?referrer=whatsapp"
                      className="w3l_facebook"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>{" "}
                  </li>
                  <li className="mx-3">
                    <a href="#" className="w3l_dribble">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li className="mx-3">
                    <a href="#" className="w3l_instagram">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          {show && (
            <div id="mySidenav" className="sidenav">
              <span className="closebtn cursor mt-5 pt-2 " onClick={() => setShow(false)}>
                &times;
              </span>
              <ul className="mt-5 " id="link-effect-5">
                <li className="active">
                  <a href="/" className="navb-text" data-hover="Home">
                    Home
                  </a>
                </li>
                <li className="active">
                  <a href="/about" className="navb-text" data-hover="About">
                    About
                  </a>
                </li>
                <li className="active">
                  <a
                    href="/product"
                    className="navb-text"
                    data-hover="Products"
                  >
                    Products
                  </a>
                </li>
                <li className="active">
                  <a href="/blog" className="navb-text" data-hover="Blog">
                    Blog
                  </a>
                </li>
                <li className="active">
                  <a href="/newsletter" className="navb-text" data-hover="Blog">
                    Newsletter
                  </a>
                </li>
                <li className="active">
                  <a href="/career" className="navb-text" data-hover="Careers">
                    Careers
                  </a>
                </li>
                <li className="active">
                  <a
                    href="/contact"
                    className="navb-text"
                    data-hover="Contact Us"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>

        <section className="slider">
          <div className="flexslider">
            <ul className="slides">
              <li>
                <div className="banner_info">
                  <h1>World Class Products</h1>
                  <p>
                    {" "}
                    We are focused on developing products that will improve the
                    quality of life through cutting edge technology and Research
                    & Development.{" "}
                  </p>
                </div>
              </li>
              <li>
                <div className="banner_info">
                  <h1>There's something for everyone</h1>
                  <p>
                    Our aim is to ensure there is a product for everyone and
                    every need.
                  </p>
                </div>
              </li>
              <li>
                <div className="banner_info">
                  <h1>Top quality products </h1>
                  <p>
                    Our experienced and committed quality control team ensures
                    all our products meet international standard using global
                    best practices.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <div className="call">
          <div className="btnk">
            <a
              href="whatsapp://send?text=Hello, I would like to order &phone=2348030565132"
              className="tel"
            >
              CHAT WITH A VET NOW!!{" "}
            </a>
            <div className="btnk2"></div>
          </div>
          <div className="btnk" style={{ marginLeft: "10px" }}>
            <a className="tel" href="tel:+234-809-473-8846">
              CALL A VET NOW!!
            </a>
            <div className="btnk2"></div>
          </div>
        </div>
      </div>
      <div className="banner-bottom" style={{ marginBottom: "50px" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 w3layouts_banner_bottom_left ">
              <img src={image3} alt="about" className="img-fluid w-100" />
            </div>
            <div className="col-md-8 w3layouts_banner_bottom_right">
              <h4>About us</h4>
              <h3>Welcome to Kachelan Pharma-Research</h3>
              <p>
                At Kachelan Pharma-Research Ltd, our people are focused on using
                cutting edge technology and R & D to manufacture & distribute
                products which not only satisfies individual customer’s needs
                but also sets the stage for pharmaceutical revolution.{" "}
              </p>
              <div className="w3_more">
                <a
                  href="#"
                  className="link link--yaku"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  <span>R</span>
                  <span>e</span>
                  <span>a</span>
                  <span>d</span> <span>M</span>
                  <span>o</span>
                  <span>r</span>
                  <span>e</span>{" "}
                </a>{" "}
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>

      {/* Bootstrap pop up */}
      <div
        className="modal video-modal fade"
        id="myModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              {" "}
              Our Mission{" "}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>{" "}
            </div>
            <section>
              <div className="modal-body">
                <div className="text-center">
                  <img src={image2} alt="mission" className="img-fluid w-75 " />
                </div>

                <p className="text-center">
                  To provide world class products through the deployment of
                  research and development in alignment with global best
                  practices and the use of highly skilled human resources
                  towards the delivery of proven pharmaceutical products
                </p>
                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-6 text-center">
                    <a href="/about">
                      <button className="pro ">MORE ABOUT US</button>
                    </a>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      {/* Bootstrap pop up */}

      {/* <!-- about --> */}
      <div className="about">
        <div className="container">
          <h2>Developing Medicine to improve life</h2>
          <div className="w3l_about_pos">
            <img src={develop} alt="develop" className="img-fluid" />
          </div>
        </div>
      </div>
      {/* <!-- //about --> */}

      {/* Youtube */}
      <div className="team">
        <div className="container">
          <p className="w3ls_para">Kachelan</p>
          <h3 className="agileits_head">Our latest videos</h3>
          <div className="agileinfo_team_grids">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <YouTube
                    className="vid-height"
                    videoId="O-UU7lRYNPU"
                    onReady={onReady}
                  />
                </div>
                <div className="col-lg-6 mb-2">
                  <YouTube
                    className="vid-height"
                    videoId="HZQ_AmotTXs"
                    onReady={onReady}
                  />
                </div>
              </div>
            </div>

            <div className="clearfix"> </div>
          </div>
        </div>
      </div>
      {/*youtube */}

      {/* <!-- team --> */}
      <div className="team">
        <div className="container">
          <p className="w3ls_para">Kachelan</p>
          <h3 className="agileits_head">Blog</h3>
          <div className="agileinfo_team_grids">
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                {" "}
                <img src={blog1} alt=" " className="img-fluid" />
                <div className="wthree_team_grid">
                  <h4>Egg Laying – Why A Chicken Won’t Lay Eggs</h4>
                  <p>
                    It is always concerning when our hens suddenly stop laying.
                    The first sign of a problem for your girls is when they stop
                    laying eggs. When chickens are lacking something they
                    require egg production is the first thing their bodies shut
                    down in order to make up for what ...
                    <a href="/blog">Continue reading</a>{" "}
                  </p>{" "}
                  <a href="/blog">
                    <input
                      type="button"
                      value="READ MORE ARTICLES"
                      className="rea"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-2"></div>
            </div>

            <div className="clearfix"> </div>
          </div>
        </div>
      </div>
      {/* <!-- //team --> */}

      {/* <!-- stats --> */}
      <div className="stats">
        <div className="container">
          <div className="row">
            <div className="col-md-3 w3_agileits_stats_grid">
              <div className="agileits_w3layouts_stats_grid">
                <p>
                  {" "}
                  <span className="counter">10,000</span>{" "}
                  <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                </p>
                <h3>Customers</h3>
              </div>
            </div>
            <div className="col-md-3 w3_agileits_stats_grid">
              <div className="agileits_w3layouts_stats_grid">
                <p>
                  {" "}
                  <span className="counter2">20</span>{" "}
                  <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                </p>
                <h3>States</h3>
              </div>
            </div>
            <div className="col-md-3 w3_agileits_stats_grid">
              <div className="agileits_w3layouts_stats_grid">
                <p>
                  {" "}
                  <span className="counter3">5</span>{" "}
                  <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                </p>
                <h3>Products</h3>
              </div>
            </div>
            <div className="col-md-3 w3_agileits_stats_grid">
              <div className="agileits_w3layouts_stats_grid">
                <p>
                  {" "}
                  <span className="counter4">500,000</span>{" "}
                  <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                </p>
                <h3>Sales</h3>
              </div>
            </div>
            <div className="clearfix"> </div>
          </div>
        </div>
      </div>
      {/*  <!-- //stats --> */}

      {/* <!-- testimonials --> */}
      <div className="testimonials">
        <div className="container">
          <p className="w3ls_para">Kachelan</p>
          <h3 className="agileits_head">What Our Clients Say</h3>
          <div className="agile_testimonials_grids">
            <ul id="flexiselDemo1">
              <li>
                <div className="agile_testimonials_grid">
                  <div className="agile_testimonials_grid1">
                    <p>
                      {" "}
                      Just to commend your organization on the antibiotics drug-
                      KACHFLOX and KACHVYTE vitamins. These your drugs have
                      helped survive stroke from the bacteria infections (coryza
                      infection, salmonella, mycoplasma, fowl cholera, fowl
                      typhoid etc.). I really appreciate you. Thanks!{" "}
                    </p>
                    <div className="w3_agile_testimonials_grid">
                      <div className="w3_agile_testimonials_grid_left"> </div>
                      <div className="w3_agile_testimonials_grid_right">
                        <h4>BABATUNDE IBRAHEEM</h4>
                        <h5>FARMER - IBADAN</h5>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="agile_testimonials_grid">
                  <div className="agile_testimonials_grid1">
                    <p>
                      {" "}
                      My vet diagnosed that birds on my farm have chronic
                      respiratory diseases and we have tried different types of
                      antibiotics which didn't work. Thanks to kachflox from
                      kachelan. The birds recovered so quickly. Kachflox from
                      kachelan have served me well. Kudos!{" "}
                    </p>
                    <div className="w3_agile_testimonials_grid">
                      <div className="w3_agile_testimonials_grid_left"> </div>
                      <div className="w3_agile_testimonials_grid_right">
                        <h4>ESTHER OLIVER</h4>
                        <h5>DELIGHT FARM - ABUJA</h5>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="agile_testimonials_grid">
                  <div className="agile_testimonials_grid1">
                    <p>
                      {" "}
                      Kachflox came out and became a wonderful drug for farmers.
                      It tackles the problems of farmers because of it's broad
                      spectrum....CRD and other bacteria diseases also helps the
                      fish farmers .{" "}
                    </p>
                    <div className="w3_agile_testimonials_grid">
                      <div className="w3_agile_testimonials_grid_left"> </div>
                      <div className="w3_agile_testimonials_grid_right">
                        <h4>ABUCHI EGWONWU</h4>
                        <h5>FISH FARMER- NIGERIA</h5>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            {/* <script
                    type="text/javascript"> $(window).load(function () { $("#flexiselDemo1").flexisel({ visibleItems: 2, animationSpeed: 1000, autoPlay: true, autoPlaySpeed: 3000, pauseOnHover: true, enableResponsiveBreakpoints: true, responsiveBreakpoints: { portrait: { changePoint: 480, visibleItems: 1 }, landscape: { changePoint: 640, visibleItems: 2 }, tablet: { changePoint: 768, visibleItems: 2 } } }); }); </script>
                <script type="text/javascript" src="js/jquery.flexisel.js"></script> */}
          </div>
        </div>
      </div>
      {/* <!-- //testimonials --> */}

      {/* Footer */}
      <Footer />
    </div>
  );
};
