import image1 from "./img/livestock.jpg";
import image2 from "./img/agro3.jpg";

export const blogData = [
  {
    title:
      "How to Raise Chickens – 5 Effective and Useful Methods to Improve Your Livestock",
    image: image1,
    row1: "One of the best sources of protein is the white meat that comes from chickens. If you will go to various food shops and restaurants, you will find several recipes using chicken as their main dish or sometimes, a side dish. To cut a long story short, chicken has been the most widely eaten food since time immemorial. Therefore, you must take care of them from the time they were hatched until they are ready for cooking. But for this to happen, you should know how to raise chickens first. You can follow these five useful methods of improving your flock.",
    row2: "1. Build coops Just like any other pets, chickens need a place to stay. They cannot be seen inside your home running around with your children because it has its own place. You can make your own coop using wood or hard cardboard or just let the carpenters do this job for you. 2. Give them space No, not the space needed in a relationship; but the space needed by your chicken for grazing. Hens, on the other hand, need to have their own privacy. Because when they are exposed to other chickens, they may feel a lot more stressed that might cause delays on laying eggs. 3. Spend time with them Forming a bond between the master and the pet is included on how to raise chickens. If you neglect them, your feet can be pecked by their powerful and sharp, mid-length beaks. So, be very careful because they can get too aggressive when ignored.",
    row3: "4. Feed them with the right food What is right for you is also right for your chickens; except for worms and insects, of course. Your chicken must have a daily serving of grains that consist of corns, wheat, and barley. Bread, meat and fish scraps are also a good choice of feed for your chickens. If you want to know how to raise chickens using the right kind of food, try to feed your chickens a whole bunch of mealworms and fly larvae. 5. Know what type of fowl you will need There are so many types of fowl available in your local farm. If you plan to sell chicken meat, you can purchase roosters because they are rich in white meat. Venturing to egg selling is only possible if you have hens. You might also want to have chickens as pets or combination. Researching about the types of chickens will be a benefit since each chicken has different temperament and purpose. These five useful ways on how to raise chickens are indeed helpful for beginners and oldies. Now, you can say to yourself that you can have your own flock even without the help of experts.",
  },

  {
    title: "Egg Laying – Why A Chicken Won’t Lay Eggs",
    image: image2,
    row1: "It is always concerning when our hens suddenly stop laying. The first sign of a problem for your girls is when they stop laying eggs. When chickens are lacking something they require egg production is the first thing their bodies shut down in order to make up for what it is lacking. Most of the time it’s an easy fix, is the coop clean? Are you feeding the proper food? Sometimes it could more complicated, are my babies sick or are the molting? Let’s take a look at the common issues and see what it takes to get the girls happy again.",
    row2: "Age: Chickens have a lifespan of 7 years are in their prime for the first year or two of them laying eggs, after that production rapidly declines until the 4th or 5th year when they usually stop laying altogether. It usually best to replace the laying hen with a new one after their 3rd year of laying eggs. Habitat: Chickens are creatures of habit, sometimes the slightest change can throw them off. Moving your hens from one location to another, adding new features or space can stress out your girls. They will not start laying again until they feel relaxed and are comfortable again. Even a dirty pen or coop could throw them off, unsanitary conditions are the best way to for the birds to contract unwanted diseases, especially if the space is too small. Food / Water: A dehydrated chicken cannot produce eggs make sure there is always water available for your hens. Using the nipple drinkers help conserve water and keep the coop clean. To produce an egg your girls need a special diet of calcium and proteins. This type of feed is called “layer feed” and comes in many different varieties from different feed or pet stores. Chickens will overeat so monitor the feed based on the number of chickens.",
    row3: "Health: Did the chicken go broody? A broody hen will not lay until she is done hatching her eggs. The girls won’t lay when they molt either. Molting is when the chickens are losing their feather due to changes in the weather. It’s the same as when a dog shed its fur, except the chicken sheds its feathers. Mites put a lot of strain on a hen’s body, she is miserable and won’t lay for you. A good sign your chicken is ill or not feeling well is her stance. If she is hunched over instead of standing up and perky she is not feeling well and has problems she probably needs help with. Stress: If a chicken is stressed she won’t lay, she needs to feel comfortable. Too many roosters can easily cause stress on your girls. Is there a predator around, is she fearful? Keep your girls happy safe and fed, and she provides you with many eggs to come. Some birds are better layers than others, like the Orpington Chicken that was bread for maximum laying capacity.",
  },
];
