import React from "react";
import logo from "../../img/logo.jpeg";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4 w3_footer_grid">
            <h3>About Us</h3>
            <p>
              We are focused on using cutting edge technology and R & D to
              manufacture & distribute products which not only satisfies
              individual customer’s needs but also sets the stage for
              pharmaceutical revolution.
            </p>
          </div>
          <div className="col-md-3 w3_footer_grid">
            <h3>Navigation</h3>
            <ul>
              <li className="active">
                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="/">Home</a>
              </li>
              <li>
                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="/about">About</a>{" "}
              </li>
              <li>
                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="/product">Products</a>
              </li>
              <li>
                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="/contact">Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="col-md-5 w3_footer_grid">
            <h3 style={{ cursor: "pointer" }}> <a style={{color:"#fff"}} href="/newsletter">Newsletter</a></h3>
          </div>
        </div>

        <div className="w3layouts_footer_grid">
          <div className="w3layouts_footer_grid_left">
            <h2>
              <a href="#">
                <img src={logo} alt="" />
              </a>
            </h2>
          </div>
          <div className="w3layouts_footer_grid_right">
            <p data-toggle="modal" data-target="#ads">
              {" "}
              © 2017 Kachelan Pharma-Research Limited. All rights reserved
            </p>
          </div>
          <div className="clearfix"> </div>
        </div>
      </div>
    </div>
  );
};
